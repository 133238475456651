<template>
  <transition name="fade">
    <new-message-mobile v-if="isUnReadMessage" />
  </transition>
  <template v-if="type != 'empty'">
    <!--div class="fx">
      <ul class="fxhead">
        <li class="atten" :class="{'on': $route.meta.gnbMenu === 'charge'}"><a @click="goPageByName('charge')"><em></em><span>{{$t('front.common.deposit')}}</span></a></li>
        <li class="notice" :class="{'on': $route.meta.gnbMenu === 'exchange'}"><a @click="goPageByName('exchange')"><em></em><span>{{$t('front.common.withdrawal')}}</span></a></li>
        <li class="home" :class="{'on': $route.meta.gnbMenu === 'betList'}"><a @click="goPageByName('betList')"><em></em><span>{{$t('front.mypage.betList')}}</span></a></li>
        <li class="ques" :class="{'on': $route.meta.gnbMenu === 'cs'}"><a @click="goPageByName('qnaList')"><em></em><span>{{$t('front.common.cscenter')}}</span></a></li>
        <li class="my" :class="{'on': $route.meta.gnbMenu === 'myinfo'}"><a @click="goPageByName('myinfo')"><em></em><span>{{$t('front.common.mypage')}}</span></a></li>
        <li class="home on"><a @click="goPageByName('mobileMain')"><span>홈</span></a></li>
      </ul>
    </div-->

    <header>
      <!--div class="noticeWrap">
        <div class="notice"><em>{{$t('front.common.notices')}}</em><span>{{$t('front.header.msg')}}</span></div>
        <div class="langbtn" @click="openLang()">
          <button class="langdropbtn" >
            <template v-if="$i18n.locale === 'ko'">
              <img src="@/assets_mobile/img/korea.png" /><span class="langsp">KO</span>
            </template>
            <template v-if="$i18n.locale === 'en'">
              <img src="@/assets/img/eng.png" /><span class="langsp">EN</span>
            </template>
            <template v-if="$i18n.locale === 'vn'">
              <img src="@/assets/img/vn.svg" /><span class="langsp">VN</span>
            </template>
            <img class="langbtnb" src="@/assets_mobile/img/langbtn.png" />
          </button>
          <div class="langdropdown" v-if="isOpenLang">
            <a href="#" :class="{'on': $i18n.locale === 'ko'}" @click="changeLocale('ko')">KR</a>
            <a href="#" :class="{'on': $i18n.locale === 'en'}" @click="changeLocale('en')">EN</a>
            <a href="#" :class="{'on': $i18n.locale === 'vn'}" @click="changeLocale('vn')">VN</a>
          </div>
        </div>
      </div-->

      <div class="header">
        <a class="menu" @click="openSide()"><img src="@/assets/img/icon_menu.svg" /></a>
        <h1 class="logo"><a @click="goPageByName('mobileMain')"><img src="@/assets/img/detective-logo.png" /></a></h1>
        <div class="logininfowrap" v-if="userData">
          <a class="icon" @click="openRight()"><img src="@/assets_mobile/img/icon_id.svg" /></a>
          <!--<ul class="mym">
            <li class="myc"><img src="@/assets_mobile/img/mycicon.png" /><span>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}</span></li>
            <li class="myp" @click="goPageByName('point')"><img src="@/assets_mobile/img/mypicon.png" /><span>{{userData.pointAmt ? thousand(userData.pointAmt.toString()): 0}}</span></li>
            <li class="myg"><img src="@/assets_mobile/img/mygicon.png" /><span>{{thousand(coinAmt ? coinAmt.toString(): '0')}}</span></li
          </ul>
          <a @click="goPageByName('msgMain')" class="mymsg"><img src="@/assets_mobile/img/message_off.svg" /><span class="mgsn">{{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}</span></a>
          <span class="headNickname">{{userData.memNick}}</span-->
        </div>
        <div v-if="!userData">
          <!--<a class="mloginbtn" @click="goPageByName('mobileJoin')">JOIN</a>-->
          <a class="mloginbtn icon" @click="goPageByName('mobileLogin')"><img src="@/assets_mobile/img/icon_id.svg" /></a>
        </div>
      </div>
    </header>
    <div class="sidemenu" :class="{'on' : sideMenuOn}">
      <button class="sideClose" @click="openSide()"><img src="@/assets/img/icon_cancelW.svg" /></button>
      <ul class="sideList">
        <li @click="goPageByName('charge')">입금신청</li>
        <li @click="goPageByName('exchange')">출금신청</li>
        <li @click="goPageByName('point')">포인트전환</li>
        <li @click="goPageByName('noticeList')">공지사항</li>
        <li @click="goPageByName('qnaList')">고객센터</li>
        <li @click="goPageByName('betList')">베팅내역</li>
        <li v-if="userData" @click="goPageByName('myinfo')">마이페이지</li>
        <li v-if="userData">
          <a :href="'/LH0'" target="_blank" v-if="userData.isPartnerMember && userData.isPartnerMember === 'Y'" style="color: #fff;">{{ $t('front.common.partner') }}</a>
        </li>
        <li v-if="siteOption" class="icon kakao" @click="siteOption['sns1Link']"><img src="@/assets_mobile/img/icon-kakao.png" />카카오톡<i>{{siteOption['sns1Id']}}</i></li>
        <li v-if="siteOption" class="icon tele" @click="siteOption['sns2Link']"><img src="@/assets_mobile/img/icon-telegram.png" />텔레그램<i>{{siteOption['sns2Id']}}</i></li>
        <li v-if="siteOption" class="icon url" @click="siteOption['sns3Link']"><img src="@/assets_mobile/img/icon-url.png" />평생주소<i></i></li>
      </ul>
    </div>
    <div class="sidemenu right" :class="{'on' : rightMenuOn}" v-if="userData">
      <button class="sideClose" @click="openRight()"><img src="@/assets/img/icon_cancelW.svg" /></button>
      <div class="sideBtn">
        <a @click="goPageByName('myinfo')">마이페이지</a>
        <a @click="signOut()" class="mylogout"><span>{{$t('front.common.logout')}}</span></a>
      </div>
      <ul class="sideBox">
        <li>
          <span>{{userData.memId}}</span>
          <a class="btn">정보수정</a>
        </li>
        <li>
          <span>보유금</span>
          <span>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}} 원</span>
        </li>
        <li>
          <span>포인트</span>
          <span>{{userData.pointAmt ? thousand(userData.pointAmt.toString()): 0}} P</span>
        </li>
        <li>
          <span>새쪽지</span>
          <span>{{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}} 개</span>
        </li>
      </ul>
      <ul class="sideList" v-if="siteOption">
        <li class="icon kakao" @click="siteOption['sns1Link']"><img src="@/assets_mobile/img/icon-kakao.png" />카카오톡<i>{{siteOption['sns1Id']}}</i></li>
        <li class="icon tele" @click="siteOption['sns2Link']"><img src="@/assets_mobile/img/icon-telegram.png" />텔레그램<i>{{siteOption['sns2Id']}}</i></li>
        <li class="icon url" @click="siteOption['sns3Link']"><img src="@/assets_mobile/img/icon-url.png" />평생주소<i></i></li>
      </ul>
    </div>
  </template>
</template>

<script>

import { mapState } from 'vuex'
import NewMessageMobile from '@/components/member/MessageMobile.vue'

export default {
  name: 'Header',
  components: { NewMessageMobile },
  props: [
    'type'
  ],
  data () {
    return {
      isOpenLang: false,
      sideMenuOn: false,
      rightMenuOn: false
    }
  },
  computed: {
    ...mapState([
      'userData',
      'unReadMessageCount',
      'siteOption'
    ]),
    isUnReadMessage () {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  methods: {
    openLang () {
      this.isOpenLang = !this.isOpenLang
    },
    onLogOut () {
      this.$root.signOut()
      this.$router.push({ name: 'main' })
      // location.reload()
    },
    openSide () {
      this.sideMenuOn = !this.sideMenuOn
    },
    openRight () {
      this.rightMenuOn = !this.rightMenuOn
    }
  }
}
</script>

<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
